body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
input {
  width: 100%;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
a {
  color: white;
  text-decoration: none;
}
.form {
  position: relative;
  z-index: 1;
  width: 300px;
  margin: 30px auto;
  background-color: #70798a;
  padding: 25px;
  border-radius: 15px;
}

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 12px;
  background: #ff31a5;
  background: linear-gradient(
    90deg,
    rgba(96, 140, 239, 1) 0%,
    rgba(255, 49, 165, 1) 30%,
    #ef27ab 100%
  );
  display: block;
}
.hide {
  position: absolute;
  top: 12px;
  left: 5px;
  color: #fff;
  text-decoration: none;
}
.menu {
  position: fixed;
  top: 12px;
  min-width: 248px;
  width: 248px;
  height: 100%;
  background-color: #282c34;
  border-right: 2px solid #b959c6;
}
.menu .add {
  text-align: center;
  margin: 15px 0;
}
.button {
  background-color: #b959c6; /* #61dafb;*/
  border-radius: 15px;
  padding: 5px 10px;
  min-width: 150px;
  color: #000;
  font-size: 0.9125rem;
  border: none;
  text-decoration: none;
  color: #fff;
}
.menu .item {
  font-size: 1.125rem;
  margin: 10px;
}

.menu .item.selected a {
  color: #b959c6;
}

.app .view {
  position: absolute;
  top: 12px;
  left: 250px;
  right: 0;
  bottom: 0;
  transition: left 0.2s;
}
.app.menuHidden .view {
  left: 0;
}
.view iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 625px) {
  .app:not(.menuHidden) .menu {
    width: 100%;
    z-index: 1;
  }
  .view {
    left: 0;
  }
}
